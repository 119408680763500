import styled from "styled-components/macro";

import { IconContainer } from "../styles/common";
import { LeadCategoryContainer } from "../leads/lead-category/lead-category.styles";
import { MEDIA_QUERY_LIMITS } from "../../config";

export const VideoContainer = styled.div`
  width: var(--root-video-lead-dimension-width);
  border-radius: var(--root-border-radius-large);
  overflow: hidden;

  position: relative;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  ${IconContainer} {
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    transform: translate(0, -50%);
    margin-left: auto;
    margin-right: auto;
  }

  &:hover {
    cursor: pointer;
  }

  @media (min-width: ${MEDIA_QUERY_LIMITS.minWidthTablet}px) {
    border-radius: unset;
  }

  ${LeadCategoryContainer} & {
    @media (min-width: ${MEDIA_QUERY_LIMITS.minWidthTablet}px) {
      width: calc(var(--root-video-lead-dimension-width) * 1.04);
      border-radius: var(--root-border-radius-1);
      overflow: hidden;
    }
  }
`;

// export const TitleCircleFrame = styled.div`
//   position: absolute;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   bottom: 1.041666667vw;
//   left: 1.041666667vw;
//   width: 10.5vw;
//   height: 10.5vw;
//   padding: 2%;
//   border: 0.15625vw solid white;
//   border-radius: 50%;
//   overflow: hidden;
// `;

// export const Title = styled.h1`
//   font-size: 1vw;
//   line-height: 1.32;
//   color: white;
// `;
