import { lazy } from "react";
import { useSmoothNavigate } from "../../../utils/custom/custom.hooks";
import {
  splitName,
  personAssetsPath,
} from "../../../utils/custom/custom.utils";

import AvatarSwiperLazyContext from "../../people/avatar-swiper-lazy-context/avatar-swiper-lazy-context.component";
import { ImageLoading } from "../../../components/loading/loading.component";
import {
  PeopleCardBlueHorizonsContainer,
  UtrenderCardPlainContainer,
  UtrenderCardSlantContainer,
  UtrenderCardB2BContainer,
  UtrenderPicture,
  UtrenderName,
} from "./utrender-card.styles";

import { DEFAULT_UTREND_CARD } from "../../../config";

const Avatar = lazy(() => import("../../people/avatar/avatar.component"));

export const UTRENDER_CARD_TYPE_CLASSES = {
  blueHorisons: "blue-horizons",
  plain: "plain",
  slant: "slant",
  b2b: "b2b",
};

const getCardType = (cardType = UTRENDER_CARD_TYPE_CLASSES.plain) =>
  ({
    [UTRENDER_CARD_TYPE_CLASSES.blueHorisons]: PeopleCardBlueHorizonsContainer,
    [UTRENDER_CARD_TYPE_CLASSES.plain]: UtrenderCardPlainContainer,
    [UTRENDER_CARD_TYPE_CLASSES.slant]: UtrenderCardSlantContainer,
    [UTRENDER_CARD_TYPE_CLASSES.b2b]: UtrenderCardB2BContainer,
  })[cardType];

const UtrenderCard = ({
  item = {},
  cardType = DEFAULT_UTREND_CARD.cardType, // default to "plain"
  insideSwiper = DEFAULT_UTREND_CARD.insideSwiper, // default to true
  borderColor = DEFAULT_UTREND_CARD.borderColor,
  withName = DEFAULT_UTREND_CARD.withName,
}) => {
  const navigate = useSmoothNavigate();
  const UtrenderCardContainer = getCardType(cardType);
  const { _id, name, talent, avatar } = item;

  if (typeof _id === "undefined") {
    throw new Error("[UtrenderCard]: _id is required");
  }

  const onClickHandler = ({ _id, talent }) => {
    if (talent) {
      navigate(`/utrenders/${_id}`);
    } else {
      navigate(`/people/${_id}`);
    }
  };

  return (
    <UtrenderCardContainer
      role="figure"
      style={{
        "--cardBorderColor": borderColor,
      }}
    >
      <UtrenderPicture
        role="img"
        onClick={() => onClickHandler({ _id, talent })}
      >
        {insideSwiper ? (
          <>
            <ImageLoading />
            <AvatarSwiperLazyContext
              data-testid="avatarSwiperLazyContext"
              className="swiper-lazy"
              data-src={personAssetsPath(avatar).avatar}
              name={name}
              alt={`utrender ${name}`}
            />
          </>
        ) : (
          <Avatar avatar={avatar} name={name} />
        )}
      </UtrenderPicture>
      {withName && name && (
        // TODO:  Will change when field will be name + surname //
        <UtrenderName
          data-testid="utrenderName"
          onClick={() => onClickHandler({ _id, talent })}
        >
          {splitName(name).map((item, i) => (
            <span key={i.toString()} data-testid={`utrenderNameSpan_${i}`}>
              {item}{" "}
            </span>
          ))}
          {/*
            // TODO:  remove //
            personVideosNumber && isDevelopmentTF && (
              <span data-testid="personVideosNumber">
                ({personVideosNumber})
              </span>
            )
            */}
        </UtrenderName>
      )}
    </UtrenderCardContainer>
  );
};

export default UtrenderCard;
