import { isString, sample, isObject } from "lodash";
import { isTestTF, ASSETS_HOST } from "../../config";

import {
  CATEGORIES_CLASSES,
  CATEGORIES_VALUES,
  searchRegEx as re,
} from "../../config";
import { ReactComponent as DefaultIcon } from "../../assets/logo-dark.svg";
import {
  getIcon,
  getColor,
} from "../../components/icon-category/icon-category.component";

import ErrorComponent from "../../components/error/error.component";

// TODO: //
// [ ] type errors should throw an Error.
//   If so you need to catch error using an error ErrorBoundary component

export const devAndProdLog = (message) => {
  if (!isTestTF) {
    console.log(message);
  }
};

// cfr: https://github.com/testing-library/jest-dom/issues/350
export const convertHexToRGB = (hexCode) => {
  let hex = hexCode.replace("#", "");

  if (hex.length === 3) {
    hex = `${hex[0]}${hex[0]}${hex[1]}${hex[1]}${hex[2]}${hex[2]}`;
  }

  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);

  return `rgb(${r}, ${g}, ${b})`;
};

// const calcSizeStr = (pixelSize = "1px", type = "max") => {
//   const value = parseInt(pixelSize);
//   const result = value / 19.2;
//
//   return `${type}(${pixelSize}, ${result}vw)`;
// };
//
// const memoCalcSizeStr = (type = "max") => {
//   let cache = {};
//
//   return (pixelSize) => {
//     if (typeof pixelSize !== "string") {
//       devAndProdLog("Error: no valid pixelSize passed to 'sizeMe' function");
//       return;
//     }
//
//     // console.log(cache);
//
//     if (pixelSize in cache === false) {
//       cache[pixelSize] = calcSizeStr(pixelSize, type);
//     } else {
//       // console.log("cache hit");
//     }
//
//     return cache[pixelSize];
//   };
// };
//
// export const memoizedMaxCalc = memoCalcSizeStr("max");
//
// export const memoizedMinCalc = memoCalcSizeStr("min");

// cfr:https://stackoverflow.com/questions/3469080/match-whitespace-but-not-newlines
export const normalizeSpaces = (str) => {
  // return emppty string if we do not pass a string
  if (!isString(str)) return "";

  return str.trim().replace(/[^\S\r\n]+/g, " ");
};

export const splitOnNewLine = (str) => {
  if (!isString(str)) return "";
  return str.split(/\r\n|\r|\n/g);
};

export const slugify = (str, ch) => {
  if (!isString(str)) return "";
  // we default to "-" and the only alternative ch we use is dash
  if (ch !== "-" && ch !== "_") ch = "_";

  return str.trim().replace(/\s+/g, ch).toLowerCase();
};

export const getRandomCategory = () => {
  return sample(CATEGORIES_VALUES);
};

export const getAcronym = (name = "") => {
  return `${name.split(" ").reduce((acc, val) => {
    return acc + val.slice(0, 1);
  }, "")}`.toUpperCase();
};

// we have cases with sinlge word name and three words names. See tests
export const splitName = (str) => {
  const parts = str.split(" ");

  if (parts.length > 2) {
    return [parts.slice(0, -1).join(" "), parts.slice(-1)[0]];
  }
  return parts;
};

export const getCategoryUiName = (categoryDbName) => {
  if (typeof categoryDbName !== "string") {
    devAndProdLog("Error: no valid categoryDbName");
    return;
  }

  const uiName = CATEGORIES_CLASSES[categoryDbName];

  if (!uiName) {
    devAndProdLog("Error: no valid matching");
    return;
  }
  return uiName;
};

export const getCategoryColor = (categoryName) => {
  if (typeof categoryName !== "string") {
    devAndProdLog("Error: no valid categoryName");
    return;
  }

  const categorySlug = slugify(categoryName);

  const color = getColor(CATEGORIES_CLASSES[categorySlug]);

  if (!color) {
    devAndProdLog("Error: no matching color");
    return "var(--primaryColor)";
  }

  return color;
};

export const getCategoryIcon = (categoryName) => {
  if (typeof categoryName !== "string") {
    devAndProdLog("Error: no valid categoryName");
    return;
  }

  const categorySlug = slugify(categoryName);

  const icon = getIcon(CATEGORIES_CLASSES[categorySlug]);

  if (!isObject(icon)) {
    devAndProdLog("Error: no matching Icon");
    return DefaultIcon;
  }

  return icon;
};

export const utrenderAssetsPath = (fileName, name, forcedSubDir) => {
  if (typeof name !== "string") {
    devAndProdLog("Error: no valid name");
    return;
  }

  return {
    profilePhoto: `${ASSETS_HOST}/images/utrenders/${slugify(
      name,
    )}/gallery/small/${fileName}`,
    ...(forcedSubDir
      ? {
          profilePhoto: `${ASSETS_HOST}/images/utrenders/${forcedSubDir}/gallery/small/${fileName}`,
        }
      : {}),
  };
};

export const personAssetsPath = (fileName, forcedFileName) => ({
  avatar: `${ASSETS_HOST}/images/people/avatars/${fileName}`,
  ...(forcedFileName
    ? {
        avatar: `${ASSETS_HOST}/images/people/avatars/${forcedFileName}`,
      }
    : {}),
});

export const videoAssetsPath = (fileName) => ({
  poster: `${ASSETS_HOST}/images/videos/posters/landscape/small/${fileName}`,
  posterPortrait: `${ASSETS_HOST}/images/videos/posters/portrait/${fileName}`,
  posterLarge: `${ASSETS_HOST}/images/videos/posters/landscape/large/${fileName}`,
});

export const cityAssetsPath = (fileName) => ({
  avatar: `${ASSETS_HOST}/images/cities/avatars/${fileName}`,
  icon: `${ASSETS_HOST}/images/cities/icons/${fileName}`,
});

export const filteredVideosByVideoId = (data, _id) => {
  try {
    return data.filter((item) => item._id !== _id);
  } catch (e) {
    devAndProdLog(e);
    return [];
  }
};

export const fallbackRender = ({ error }) => {
  return <ErrorComponent data-testid="error" message={error.message} />;
};

export const searchValidation = (input) => {
  if (!isString(input)) {
    return false;
  }

  const searchString = input.toLowerCase().trim();

  if (searchString.length < 3 || searchString.length > 24) {
    return false;
  }

  if (!re.exec(searchString)) {
    return false;
  }

  return true;
};

export const isWebViewAndroid = (agent = navigator.userAgent) => {
  return agent.includes("wv");
};

export const delayReturn = (data = {}, secs = 2) => {
  return new Promise((resolve) =>
    setTimeout(() => {
      resolve(data);
    }, secs * 1000),
  );
};

export const errorsToString = (errors = {}) => {
  try {
    return Object.values(errors).join(", ");
  } catch (e) {
    devAndProdLog(e);
  }
};
