import { useWindowWidth } from "@react-hook/window-size";
import Image from "../../image/image-component";
import PosterFallback from "../../../assets/poster-fallback.png";
import { PosterContainer } from "../../styles/common";
import { videoAssetsPath } from "../../../utils/custom/custom.utils";
import { VideoCardContainer } from "./video-card.styles";
import VideoCardHoverTrigger from "../video-card-hover-trigger/video-card-hover-trigger.component";

const VideoCard = ({ width, _id, displayName, episodePoster, ...rest }) => {
  if (typeof _id === "undefined") {
    // Here we let it go... just notify the user on console and remove popup
    // throw new Error("[UtrenderCardLarge]: _id is required");
    console.log("[VideoCard]: _id is required", {
      id: _id,
      diplayName: displayName,
    });
  }
  const viewportWidth = useWindowWidth();

  // distance in pixels from trigger has to be relative to windowView since video-card changes
  const sideOffset = viewportWidth * -0.2;

  const props = {
    ...rest,
    _id,
    episodePoster,
    displayName,
    sideOffset,
  };

  return (
    <VideoCardContainer
      style={{
        "--videoCardWidth": width,
      }}
    >
      <PosterContainer ratio={16 / 9}>
        <Image
          src={videoAssetsPath(episodePoster).poster || PosterFallback}
          alt={displayName || "Nome episodio"}
        />
      </PosterContainer>
      <VideoCardHoverTrigger {...props} />
    </VideoCardContainer>
  );
};

export default VideoCard;
