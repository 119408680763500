import { ErrorBoundary } from "react-error-boundary";
import React, { lazy } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { RealmAppProvider } from "./contexts/realmapp.context";
import { RealmApolloProvider } from "./contexts/apollo.context";
import AnomWrapper from "./components/anom-wrapper/anom-wrapper.component";

import "./index.css";
import App from "./App";
// import reportWebVitals from "./reportWebVitals";
import * as realmConfig from "./config.js";
import { OtteraProvider } from "./contexts/ottera.context";

const ErrorPage = lazy(
  () => import("./routes/error-page/error-page.component"),
);

function fallbackRender({ error, _resetErrorBoundary }) {
  // Call resetErrorBoundary() to reset the error boundary and retry the render.
  return <ErrorPage message={error.message} />;
}

const REALM_APP_ID = realmConfig.appId;
const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  // <React.StrictMode>
  <ErrorBoundary fallbackRender={fallbackRender}>
    <RealmAppProvider appId={REALM_APP_ID}>
      <AnomWrapper>
        <RealmApolloProvider>
          <BrowserRouter>
            <OtteraProvider>
              <App />
            </OtteraProvider>
          </BrowserRouter>
        </RealmApolloProvider>
      </AnomWrapper>
    </RealmAppProvider>
  </ErrorBoundary>,
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
