// cfr: https://beta.reactjs.org/learn/reusing-logic-with-custom-hooks
// If your function doesn’t call any Hooks, avoid the use prefix. Instead, write it as a regular function

import { useNavigate, useLocation } from "react-router-dom";
import { isWebViewAndroid } from "./custom.utils";
// import { useState, useEffect } from "react";

/*
function useOnlineStatus() {
  const [isOnline, setIsOnline] = useState(true);
  useEffect(() => {
    function handleOnline() {
      setIsOnline(true);
    }
    function handleOffline() {
      setIsOnline(false);
    }
    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);
    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);
  return isOnline;
}
*/

export const useMobileBrowsers = () => {
  let hasTouchScreen = false;

  if ("maxTouchPoints" in navigator) {
    hasTouchScreen = navigator.maxTouchPoints > 0;
  } else if ("msMaxTouchPoints" in navigator) {
    hasTouchScreen = navigator.msMaxTouchPoints > 0;
  } else {
    const mQ = matchMedia?.("(pointer:coarse)");
    if (mQ?.media === "(pointer:coarse)") {
      hasTouchScreen = !!mQ.matches;
    } else if ("orientation" in window) {
      hasTouchScreen = true; // deprecated, but good fallback
    } else {
      // Only as a last resort, fall back to user agent sniffing
      const UA = navigator.userAgent;
      hasTouchScreen =
        /\b(BlackBerry|webOS|iPhone|IEMobile)\b/i.test(UA) ||
        /\b(Android|Windows Phone|iPad|iPod)\b/i.test(UA);
    }
  }

  return hasTouchScreen;
};

export const useWebViewAndroid = () => {
  return isWebViewAndroid();
};

export const useSmoothNavigate = () => {
  const navigate = useNavigate();

  return (url = "/") => {
    // console.log("url smooth: ", url);
    navigate(url);
    window.scroll({ top: 0, left: 0, behavior: "smooth" });
  };
};

export const useRelativePath = () => {
  const location = useLocation();
  const specialPattern = /^\/orizzonti/;
  return specialPattern.test(location.pathname);
};
