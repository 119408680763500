import { useNavigate } from "react-router-dom";
import { videoAssetsPath } from "../../utils/custom/custom.utils";
import Image from "../image/image-component";
// import { IconContainer } from "../styles/common";
// import { ReactComponent as PlayIcon } from "../../assets/icons/buttons/play.svg";
import * as AspectRatio from "@radix-ui/react-aspect-ratio";

import PosterFallback from "../../assets/poster-fallback.png";

import { VideoContainer } from "./video-lead.styles";
import { Mobile, TabletAndDesktop } from "../styles/common";

const VideoLead = ({ _id, displayName, episodePoster }) => {
  const navigate = useNavigate();
  const onClickHandler = () => {
    navigate(`/videos/${_id}`);
  };

  // console.log(episodePoster);

  return (
    <VideoContainer onClick={onClickHandler}>
      <AspectRatio.Root ratio={16 / 9}>
        <Mobile>
          <Image
            src={videoAssetsPath(episodePoster).poster || PosterFallback}
            alt={`video ${displayName}`}
          />
        </Mobile>
        <TabletAndDesktop>
          <Image
            src={videoAssetsPath(episodePoster)?.posterLarge || PosterFallback}
            alt={`video ${displayName}`}
          />
        </TabletAndDesktop>
        {/*
          <IconContainer
            style={{
              "--width": "clamp(100px, 11.458333333vw, 600px)",
              "--height": "clamp(100px, 11.458333333vw, 600px)",
            }}
          >
            <PlayIcon />
          </IconContainer>
        */}
      </AspectRatio.Root>
    </VideoContainer>
  );
};

export default VideoLead;
