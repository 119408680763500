import styled from "styled-components/macro";

import { LeadContainer as LeadBase } from "../common/lead.styles";

import { MEDIA_QUERY_LIMITS } from "../../../config";

export const LeadHomeContainer = styled(LeadBase)`
  @media (max-width: ${MEDIA_QUERY_LIMITS.maxWidthMobile}px) {
    padding-bottom: var(--root-vertical-space-3);
  }
`;
