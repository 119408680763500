import { useQuery } from "@apollo/client";
import gql from "graphql-tag";
import { SwiperSlide } from "swiper/react";

// import { sample } from "lodash";

import { Loading } from "../../loading/loading.component";
import Error from "../../error/error.component";
import ViewLeadSwiper from "../../views/view-lead-swiper/view-lead-swiper.component";
import VideoLead from "../../video-lead/video-lead.component";

// const SHOWCASE_VIDEOS = gql`
//   query SHOWCASE_VIDEOS($input: VideosByCategoryInput!) {
//     videosByCategory(input: $input) {
//       _id
//       episodePoster
//       displayName
//     }
//   }
// `;

const SHOWCASE_VIDEOS = gql`
  query GALLERY_HOME_VIDEOS(
    $query: VideoQueryInput
    $limit: Int
    $sortBy: VideoSortByInput
  ) {
    videos(query: $query, limit: $limit, sortBy: $sortBy)
      @connection(key: "homeGallery") {
      _id
      episodePoster
      displayName
    }
  }
`;

// const LEAD_CATEGORY = sample([
//   "food",
//   "events",
//   "travel",
//   "entertainment",
//   "fashion",
//   "design",
//   "art",
//   "blue-horizons",
//   "beauty",
//   "music",
// ]);

const SwiperLeadVideos = () => {
  const query = { homeGalleryScore_exists: true };
  const limit = 4;
  const sortBy = "HOMEGALLERYSCORE_DESC";

  const { loading, error, data } = useQuery(SHOWCASE_VIDEOS, {
    variables: {
      query,
      limit,
      sortBy,
    },
  });

  // data && console.log(data);

  return error ? (
    <Error message={error.message} />
  ) : loading ? (
    <Loading minHeight={"var(--root-video-lead-dimension-height)"} />
  ) : (
    <ViewLeadSwiper>
      {data?.videos?.length > 0
        ? data?.videos?.map((item, i) => (
            <SwiperSlide key={item._id ? item._id : i.toString()}>
              <VideoLead {...item} />
            </SwiperSlide>
          ))
        : null}
    </ViewLeadSwiper>
  );
};

export default SwiperLeadVideos;
