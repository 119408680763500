import { useQuery } from "@apollo/client";
import gql from "graphql-tag";
import { Suspense, lazy } from "react";

import { Loading } from "../../../components/loading/loading.component";
import SwiperPeopleList from "../../lists/swiper-people-list/swiper-people-list.component";

const Error = lazy(() => import("../../../components/error/error.component"));

// TODO: move filter to personVideosNumber > 0 server-side
// you need to us topPeople and change funcions in the backend.

// default limit to 100
//
// we now use a direct query sorting by homeGalleryScore
const PEOPLE = gql`
  query PEOPLE($input: Int) {
    topPeople(input: $input) @connection(key: "peopleSwiper") {
      _id
      name
      avatar
      talent
      personVideosNumber
    }
  }
`;

// const PEOPLE = gql`
//   query PEOPLE($query: PersonQueryInput, $limit: Int) {
//     people(query: $query, limit: $limit) {
//       _id
//       name
//       talent
//       avatar
//       personVideosNumber
//     }
//   }
// `;

const SwiperPeople = () => {
  const { loading, error, data } = useQuery(PEOPLE, {
    variables: {
      input: 150,
    },
  });

  return error ? (
    <Suspense fallback={<Loading />}>
      <Error message={error.message} />
    </Suspense>
  ) : loading ? (
    <Loading minHeight={"var(--root-min-height-people-row)"} />
  ) : (
    <SwiperPeopleList
      data={data.topPeople.filter((item) => item.personVideosNumber > 0)}
      // data={data.people}
      cardborderColor="var(--primaryColor)"
      arrowsColor="var(--secondaryColor)"
    />
  );
};

export default SwiperPeople;
