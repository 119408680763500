import { videoAssetsPath } from "../../../utils/custom/custom.utils";
import {
  useRelativePath,
  useSmoothNavigate,
} from "../../../utils/custom/custom.hooks";
import * as HoverCard from "@radix-ui/react-hover-card";
import * as VisuallyHidden from "@radix-ui/react-visually-hidden";

import Image from "../../image/image-component";
import { IconContainer } from "../../styles/common";
import { ReactComponent as PlayIcon } from "../../../assets/icons/buttons/play-dark.svg";
import { toast } from "react-toastify";

import { DEFAULT_MOBILE_HOVER_PADDING } from "../../../config";

import {
  HoverCardContainer,
  HoverCardPosterContainer,
  HoverCardTitle,
  HoverCardPlot,
  ButtonsContainer,
  TriggerContainer,
  HoverCardPlotAndTitleContainer,
} from "./video-card-hover-trigger.styles.jsx";

import PosterFallback from "../../../assets/poster-fallback.png";

const VideoCardHoverTrigger = ({
  _id,
  displayName,
  sideOffset,
  collisionPadding = DEFAULT_MOBILE_HOVER_PADDING,
  preview,
  episodePoster,
  plot,
  borderColor,
}) => {
  const useRelative = useRelativePath();
  // console.log("useRelativePath? ", useRelative);

  const navigate = useSmoothNavigate();

  const onClickHandler = (id) => {
    if (!id) {
      console.log("[ERROR: Episode card] no id on click");
      toast("💥 Sorry: this video is not available.");
      return;
    }
    useRelative ? navigate(`videos/${id}`) : navigate(`/videos/${id}`);
  };

  return (
    <HoverCard.Root openDelay={1000} closeDelay={400}>
      <HoverCard.Trigger asChild>
        <TriggerContainer onClick={() => onClickHandler(_id)}>
          <VisuallyHidden.Root>{_id}</VisuallyHidden.Root>
        </TriggerContainer>
      </HoverCard.Trigger>
      <HoverCard.Portal>
        <HoverCardContainer
          avoidCollisions={true}
          collisionPadding={collisionPadding}
          sideOffset={sideOffset}
          align="center"
          hideWhenDetached={false}
          style={{
            "--borderColor": borderColor,
          }}
        >
          <HoverCardPosterContainer
            ratio={16 / 9}
            onClick={() => onClickHandler(_id)}
          >
            <Image
              src={
                preview ||
                videoAssetsPath(episodePoster).poster ||
                PosterFallback
              }
              alt={displayName || "Nome episodio"}
            />
          </HoverCardPosterContainer>
          <HoverCardPlotAndTitleContainer>
            <HoverCardTitle>{displayName || "Nome Episodio"}</HoverCardTitle>
            <HoverCardPlot>{plot}</HoverCardPlot>
          </HoverCardPlotAndTitleContainer>
          <ButtonsContainer>
            <IconContainer
              onClick={() => onClickHandler(_id)}
              style={{
                "--width": "max(45px, 4.166666667vw)",
                "--height": "max(45px, 4.166666667vw)",
              }}
            >
              <PlayIcon />
            </IconContainer>
          </ButtonsContainer>
        </HoverCardContainer>
      </HoverCard.Portal>
    </HoverCard.Root>
  );
};

export default VideoCardHoverTrigger;
