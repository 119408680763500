import { useMediaQuery } from "react-responsive";
import { useQuery } from "@apollo/client";
import gql from "graphql-tag";
import { SwiperSlide } from "swiper/react";
import { Suspense, lazy } from "react";

import { Loading } from "../../loading/loading.component";
import { BackgroundContainer as Div } from "../../styles/common";
import ViewSwiperBase from "../../views/view-swiper-base/view-swiper-base.component";
import VideoCardSlanted from "../../cards/video-card-slanted/video-card-slanted.component";
import { MEDIA_QUERY_LIMITS } from "../../../config";

import styled from "styled-components/macro";

const Error = lazy(() => import("../../../components/error/error.component"));

const BackgroundContainer = styled(Div)`
  height: calc(var(--root-video-card-slanted-height) / 10 * 9);
  background: var(--background, var(--veryperiColor));
`;

const SwiperContainer = styled.div`
  position: relative;
`;

/* Nota che questa query interroga direttamente videos */
const TOP_OF_WEEK_VIDEOS = gql`
  query TOP_OF_WEEK_VIDEOS(
    $limit: Int
    $query: VideoQueryInput
    $sortBy: VideoSortByInput
  ) {
    videos(limit: $limit, query: $query, sortBy: $sortBy) {
      _id
      displayName
      episodePosterPortrait
      score
      categories {
        name
      }
    }
  }
`;

const SwiperTopOfWeekVideos = ({ category, personId, background }) => {
  const isMobile = useMediaQuery({
    maxWidth: MEDIA_QUERY_LIMITS.maxWidthMobile,
  });

  const personTopQuery = {
    people_in: {
      _id: personId,
    },
  };

  const categoryTopQuery = {
    categories_in: {
      name: category,
    },
  };

  const defaultQuery = {
    _id_exists: true,
  };

  // [Warn] we are assuming we never need a query of specific person in a certain category
  const query = personId
    ? personTopQuery
    : category
      ? categoryTopQuery
      : defaultQuery;

  const variables = {
    limit: 10,
    sortBy: "SCORE_DESC",
    query,
  };

  const { loading, error, data } = useQuery(TOP_OF_WEEK_VIDEOS, {
    variables,
  });

  // console.log(variables);

  // data && console.log(data);

  return error ? (
    <Suspense fallback={<Loading />}>
      <Error message={error.message} />
    </Suspense>
  ) : loading ? (
    <Loading minHeight={"var(--root-min-height-video-slanted-row)"} />
  ) : (
    <SwiperContainer>
      <BackgroundContainer
        style={{
          "--background": background,
        }}
      />
      <ViewSwiperBase
        padding={"0 0 3.020833333vw 6vw"}
        navigation={false}
        freeMode={true}
        spaceBetween={isMobile ? 4 : 0}
        grabCursor={true}
      >
        {data.videos?.length
          ? data.videos.map((item, i) => (
              <SwiperSlide
                key={item._id ? item._id : i.toString()}
                style={{
                  width: "min-content",
                  marginLeft: `${
                    isMobile ? "0" : "var(--root-horizontal-space-0)"
                  }`,
                }}
              >
                <VideoCardSlanted
                  {...item}
                  rank={i + 1}
                  background={background}
                />
              </SwiperSlide>
            ))
          : null}
      </ViewSwiperBase>
    </SwiperContainer>
  );
};

export default SwiperTopOfWeekVideos;
