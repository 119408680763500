import { useRealmApp } from "../contexts/realmapp.context";
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  HttpLink,
} from "@apollo/client";
import { anonymousLogin } from "../utils/realm/realm.utils";
import ErrorPage from "../routes/error-page/error-page.component";
import { useEffect, useState } from "react";

const getValidAccessToken = async (app) => {
  if (!app.currentUser) {
    console.log(
      "[WARN]: setting anomUser from apollo context. AnomWrapper should have missed it",
    );
    // If no user is logged in, log in an anonymous user. The logged in user will have a valid
    // access token.
    anonymousLogin(app);
  } else {
    // An already logged in user's access token might be stale. Tokens must be refreshed after
    // 30 minutes. To guarantee that the token is valid, we refresh the user's access token.
    //
    try {
      if (typeof app.currentUser.refreshAccessToken !== "undefined") {
        await app.currentUser.refreshAccessToken();
      } else {
        // Refreshing a user's custom data also refreshes their access token
        await app.currentUser.refreshCustomData();
      }
    } catch (e) {
      console.log(`[ERROR while refreshingAccessToken in Apollo ${e}`);
      app.dumpUser();
    }
  }

  return app.currentUser.accessToken;
};

const createRealmApolloClient = (app) => {
  const link = new HttpLink({
    // Realm apps use a standard GraphQL endpoint, identified by their App ID
    uri: `https://eu-central-1.aws.realm.mongodb.com/api/client/v2.0/app/${app.id}/graphql`,
    // We define a custom fetch handler for the Apollo client that lets us authenticate GraphQL requests.
    // The function intercepts every Apollo HTTP request and adds an Authorization header with a valid
    // access token before sending the request.
    fetch: async (uri, options) => {
      const accessToken = await getValidAccessToken(app);
      options.headers.Authorization = `Bearer ${accessToken}`;
      return fetch(uri, options);
    },
  });

  const cache = new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          videosByCategory: {
            keyArgs: ["input", ["category"]],
            merge(
              existing,
              incoming,
              {
                args: {
                  input: { skip },
                },
              },
            ) {
              // console.log("Apollo-cache", { skip });
              // Slicing is necessary because the existing data is
              // immutable, and frozen in development.
              const merged = existing ? existing.slice(0) : [];
              for (let i = 0; i < incoming.length; ++i) {
                merged[skip + i] = incoming[i];
              }
              return merged;
            },
          },
          videosNewArrival: {
            keyArgs: [],
            merge(
              existing,
              incoming,
              {
                args: {
                  input: { skip },
                },
              },
            ) {
              const merged = existing ? existing.slice(0) : [];
              for (let i = 0; i < incoming.length; ++i) {
                merged[skip + i] = incoming[i];
              }
              return merged;
            },
          },
          // In order to use this we have to resolve the top videos queries. che usano gli stessi dati? oppure modificare merge function
          // this data is used by episode-stack.component
          // Do we really need to collect this? yes we need pagination...
          videos: {
            // keyArgs: ["@connection", ["key"]],
            keyArgs: [
              "query",
              [
                "seasonNumber",
                "showId",
                "people_in",
                "categories_in",
                // "homeGalleryScore_exists",
              ],
              "@connection",
              ["key"],
            ],
            merge(
              existing,
              incoming,
              {
                args: {
                  query: { episodeNumber_gt: skip = 0 },
                },
              },
            ) {
              // console.log("skip is", skip);
              // console.log("prev", existing);
              // console.log("in", incoming);
              const merged = existing ? existing.slice(0) : [];
              for (let i = 0; i < incoming.length; ++i) {
                merged[skip + i] = incoming[i];
              }
              return merged;
            },
          },
        },
      },
      Category: {
        fields: {
          categoryVideos: {
            keyArgs: [],
            merge(
              existing,
              incoming,
              {
                args: {
                  input: { skip },
                },
              },
            ) {
              const merged = existing ? existing.slice(0) : [];
              for (let i = 0; i < incoming.length; ++i) {
                merged[skip + i] = incoming[i];
              }
              return merged;
            },
          },
          categoryLastVideos: {
            keyArgs: [],
            merge(
              existing,
              incoming,
              {
                args: {
                  input: { skip },
                },
              },
            ) {
              const merged = existing ? existing.slice(0) : [];
              for (let i = 0; i < incoming.length; ++i) {
                merged[skip + i] = incoming[i];
              }
              return merged;
            },
          },
        },
      },
    },
  });
  // const cache = new InMemoryCache();
  // return new ApolloClient({ link, cache, defaultOptions });
  return new ApolloClient({ link, cache });
};

export const RealmApolloProvider = ({ children }) => {
  const app = useRealmApp();
  const [client, setClient] = useState(createRealmApolloClient(app));

  useEffect(() => {
    setClient(createRealmApolloClient(app));
  }, [app]);

  if (client) {
    return <ApolloProvider client={client}>{children}</ApolloProvider>;
  } else {
    return <ErrorPage message="no ApolloClient" />;
  }
};
