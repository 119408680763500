// last commit before build f584bd4

import { lazy } from "react";

const Bergamo = lazy(
  () => import("./components/icons/cities/bergamo-component"),
);
const Brescia = lazy(
  () => import("./components/icons/cities/brescia-component"),
);
const Cortina = lazy(
  () => import("./components/icons/cities/cortina-component"),
);
const Dubai = lazy(() => import("./components/icons/cities/dubai-component"));
const Havana = lazy(() => import("./components/icons/cities/havana-component"));
const LosAngeles = lazy(
  () => import("./components/icons/cities/los-angeles-component"),
);
const Milano = lazy(() => import("./components/icons/cities/milano-component"));
const MonteCarlo = lazy(
  () => import("./components/icons/cities/monte-carlo-component"),
);
const Monza = lazy(() => import("./components/icons/cities/monza-component"));
const Pavia = lazy(() => import("./components/icons/cities/pavia-component"));
const Riad = lazy(() => import("./components/icons/cities/riad-component"));
const Roma = lazy(() => import("./components/icons/cities/roma-component"));
const StMoritz = lazy(
  () => import("./components/icons/cities/st-moritz-component"),
);
const Tokyo = lazy(() => import("./components/icons/cities/tokyo-component"));
const Torino = lazy(() => import("./components/icons/cities/torino-component"));
const Verona = lazy(() => import("./components/icons/cities/verona-component"));
const ReggioEmilia = lazy(
  () => import("./components/icons/cities/reggio-emilia-component"),
);
const Washington = lazy(
  () => import("./components/icons/cities/washington-component"),
);
const Istanbul = lazy(
  () => import("./components/icons/cities/istanbul-component"),
);

const CITIES = {
  Bergamo: Bergamo,
  Brescia: Brescia,
  Cortina: Cortina,
  Dubai: Dubai,
  Havana: Havana,
  "Los Angeles": LosAngeles,
  Milano: Milano,
  "Monte Carlo": MonteCarlo,
  Monza: Monza,
  Pavia: Pavia,
  Riad: Riad,
  Roma: Roma,
  "St. Moritz": StMoritz,
  Tokyo: Tokyo,
  Torino: Torino,
  Verona: Verona,
  "Reggio Emilia": ReggioEmilia,
  Washington: Washington,
  Istanbul: Istanbul,
};

export const getCitySvgText = (name) => {
  return CITIES[name] ?? null;
};

const currentEnv = process.env.NODE_ENV;

export const isDevelopmentTF = (() => currentEnv === "development")();
export const isTestTF = (() => currentEnv === "test")();

export const getEnv = () => {
  !isTestTF && console.log("[SET] env.Node:", currentEnv);
};

export const authcallback = process.env.REACT_APP_AUTHCALLBACK;
export const appId = process.env.REACT_APP_REALM_APP_ID;
export const anomKey = process.env.REACT_APP_ANOM_KEY;
export const SPECIAL_CATEGORY = "blue-horizons";
export const ASSETS_HOST = "https://uta-dist.s3.amazonaws.com";
// export const ASSETS_HOST = "https://utrendtv-assets.s3.amazonaws.com";
export const isAtlasDev = (() => appId === "utrendtv-dev-mweyh")();

//  la chiave è il dato che arriva dal db: name
export const CATEGORIES_CLASSES = {
  "blue-horizons": "Orizzonti",
  architecture: "Architettura",
  art: "Arte",
  automotive: "Motori",
  beauty: "Beauty",
  design: "Design",
  entertainment: "Spettacolo",
  events: "Eventi",
  fashion: "Moda",
  fintech: "Fintech",
  food: "Food&Drink",
  music: "Musica",
  sport: "Sport",
  technology: "Tecnologia",
  travel: "Viaggi",
};

export const CATEGORIES = Object.keys(CATEGORIES_CLASSES);
export const CATEGORIES_VALUES = Object.values(CATEGORIES_CLASSES);
export const CATEGORIES_MIN_VIDEOS_NUMBER = 1;
export const NUMBER_OF_TOP_UTRENDERS = 10;
export const NUMBER_OF_TOP_CITIES = 10;
export const ITEMS_PER_PAGINATION = 6;

export const NUMBER_OF_CITIES = Object.keys(CITIES)?.length || 100;

export const SWIPER_GAP = {
  base: 10,
  mobile: 20,
};

export const DEFAULT_SWIPER_OPTIONS = {
  spaceBetween: SWIPER_GAP.base,
  padding: "0",
  margin: "0",
  direction: "horizontal",
  loadMore: () => {},
  width: "100%",
  height: "100%",
  slidesPerView: "auto",
  freeMode: false,
  grabCursor: false,
  navigation: false,
  grid: null,
  overflow: "hidden",
  arrowsColor: "var(--arrowsColor)",
};

export const DEFAULT_MOBILE_HOVER_PADDING = {
  top: 10,
  bottom: 10,
  left: 10,
  right: 10,
};

export const DEFAULT_UTREND_CARD = {
  cardType: "plain",
  insideSwiper: true,
  borderColor: "var(--primaryColor)",
  withName: true,
};

export const DEFAULT_VIDEO_CARD = {
  borderColor: "var(--secondaryColor)",
};

export const MEDIA_QUERY_LIMITS = {
  superSmall: 430,
  maxWidthSmallMobile: 540,
  minWidthLargeMobile: 541,
  maxWidthMobile: 767,
  minWidthTablet: 768,
  maxWidthTablet: 1023,
  minWidthDesktop: 1024,
  minWidthMediumDesktop: 1200,
  minWidthMediumLargeDesktop: 1700,
};

export const DEFAULT_SOCIALS = {
  message: false,
  patron: false,
  shop: false,
  nft: false,
  collab: false,
  exp: false,
};

export const searchRegEx = /^[\sa-zA-Z0-9Èèéòàùì_\-#']+$/;

// TODO: categoires with 0 videos are not here. Integrate if needed
export const sampleVideoIdsOneByCategory = [
  { cat: "food", id: "6468598f96e88a140f4c3abc" },
  { cat: "events", id: "6468598f96e88a140f4c3bb0" },
  { cat: "entertainment", id: "6468598f96e88a140f4c3bce" },
  { cat: "travel", id: "6468598f96e88a140f4c3bad" },
  { cat: "design", id: "6468598f96e88a140f4c3bd9" },
  { cat: "fashion", id: "6468598f96e88a140f4c3aff" },
  { cat: "architecture", id: "6468598f96e88a140f4c3ac7" },
  { cat: "art", id: "6468598f96e88a140f4c3ad7" },
  { cat: "sport", id: "6468598f96e88a140f4c3adb" },
  { cat: "blue-horizons", id: "6468598f96e88a140f4c3b5a" },
  { cat: "beauty", id: "6468598f96e88a140f4c3aef" },
  { cat: "music", id: "6468598f96e88a140f4c3b00" },
];

getEnv();
