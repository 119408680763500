import { useNavigate } from "react-router-dom";

import { cityAssetsPath } from "../../../utils/custom/custom.utils";

import { CityContainer, ButtonContainer } from "./city-card.styles";

const CityCard = ({ item }) => {
  const navigate = useNavigate();

  const onClickHandler = () => {
    if (!item.name) {
      console.log("[Error]: no city.name specified");
      return;
    }

    navigate(`/cities/${item.name}`);
    document.getElementById("root").scrollIntoView({ behavior: "smooth" });
  };

  return (
    <CityContainer ratio={60 / 29}>
      <img src={cityAssetsPath(item.icon).icon} alt={`logo ${item.name}`} />

      <ButtonContainer onClick={onClickHandler} />
    </CityContainer>
  );
};

export default CityCard;
