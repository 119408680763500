import { useQuery } from "@apollo/client";
import gql from "graphql-tag";
import { Suspense, lazy, useState } from "react";

import { Loading } from "../../loading/loading.component";
import VideosSwiper from "../../lists/videos-swiper/videos-swiper.component";
import { ITEMS_PER_PAGINATION } from "../../../config";

const Error = lazy(() => import("../../../components/error/error.component"));

const NEW_VIDEOS = gql`
  query NEW_VIDEOS($input: PaginatedVideosInput) {
    videosNewArrival(input: $input) {
      _id
      displayName
      episodePoster
      preview
      plot
    }
  }
`;

const SwiperNewVideos = () => {
  const [completed, setCompleted] = useState(false);
  const initialPage = {
    skip: 0,
    first: ITEMS_PER_PAGINATION,
  };

  const { loading, error, data, fetchMore } = useQuery(NEW_VIDEOS, {
    variables: {
      input: { ...initialPage },
    },
  });

  const loadMore = () => {
    // console.log("load more");
    if (completed) {
      // console.log("Pagination is completed do nothing");
      return;
    }

    const skip = data.videosNewArrival.length;
    const input = {
      ...initialPage,
      skip,
    };

    fetchMore({
      variables: {
        input,
      },
    }).then((fetchMoreResult) => {
      // console.log(fetchMoreResult);
      if (fetchMoreResult.data.videosNewArrival.length === 0) {
        setCompleted(true);
      }
    });
  };

  // data && console.log(data);

  return error ? (
    <Suspense
      fallback={<Loading minHeight={"var(--root-min-height-video-row)"} />}
    >
      <Error message={error.message} />
    </Suspense>
  ) : loading ? (
    <Loading minHeight={"var(--root-min-height-video-row)"} />
  ) : (
    <VideosSwiper data={data?.videosNewArrival} loadMore={loadMore} />
  );
};

export default SwiperNewVideos;
