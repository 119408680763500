import { useSmoothNavigate } from "../../../utils/custom/custom.hooks";

import Image from "../../../components/image/image-component";
import { videoAssetsPath } from "../../../utils/custom/custom.utils";

import PosterFallback from "../../../assets/poster-fallback.png";

import {
  SlantedVideoCardContainer,
  Rank,
  ButtonContainer,
} from "./video-card-slanted.styles";

const VideoCardSlanted = ({
  _id,
  displayName,
  episodePosterPortrait,
  rank,
  background,
}) => {
  const navigate = useSmoothNavigate();

  const onClickHandler = (id) => {
    navigate(`/videos/${id}`);
  };

  return (
    <SlantedVideoCardContainer>
      <Image
        src={
          videoAssetsPath(episodePosterPortrait).posterPortrait ||
          PosterFallback
        }
        alt={displayName || "Nome episodio"}
      />
      <Rank
        style={{
          "--background": background,
        }}
      >
        {rank.toString().padStart(2, 0)}
      </Rank>
      <ButtonContainer onClick={() => onClickHandler(_id)} />
    </SlantedVideoCardContainer>
  );
};

export default VideoCardSlanted;
