import { Swiper } from "swiper/react";
import { Pagination } from "swiper";

import { boxShadowStrong } from "../../styles/common";
import styled from "styled-components/macro";
import { LeadContainer as B2BLeadContainer } from "../../leads/lead-b2b/lead-b2b.styles";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import { MEDIA_QUERY_LIMITS } from "../../../config";

export const ViewSwiperContainer = styled(Swiper)`
  padding: var(--paddingSwiper, 0);
  height: var(--root-video-lead-dimension-height, 100%);
  flex-basis: var(--root-video-lead-dimension-width, 100%);

  .swiper-horizontal
    > .swiper-pagination-bullets.swiper-pagination-bullets-dynamic,
  .swiper-pagination-horizontal.swiper-pagination-bullets.swiper-pagination-bullets-dynamic {
    left: 95%;
    transform: translateX(-95%);
  }

  ${B2BLeadContainer} & {
    @media screen and (min-width: ${MEDIA_QUERY_LIMITS.minWidthTablet}px) {
      ${boxShadowStrong};
    }
  }
`;

const DEFAULT_SWIPER = {
  spaceBetween: 30,
  padding: "0",
};

const ViewLeadSwiper = ({
  spaceBetween = DEFAULT_SWIPER.spaceBetween,
  padding = DEFAULT_SWIPER.padding,
  children,
}) => {
  return (
    <ViewSwiperContainer
      spaceBetween={spaceBetween}
      slidesPerView={1}
      centeredSlides={true}
      pagination={{
        clickable: true,
        dynamicBullets: true,
      }}
      modules={[Pagination]}
      style={{
        "--paddingSwiper": padding,
      }}
    >
      {children}
    </ViewSwiperContainer>
  );
};

export default ViewLeadSwiper;
