import { useMediaQuery } from "react-responsive";

import { useQuery } from "@apollo/client";
import gql from "graphql-tag";
import { Suspense, lazy, useCallback } from "react";

import { getCategoryUiName } from "../../utils/custom/custom.utils";
import { Loading } from "../../components/loading/loading.component";
import ViewRow from "../../components/views/view-row/view-row.component";
import ViewRowHeader from "../../components/views/view-row-header/view-row-header.component";
import ViewRowContent from "../../components/views/view-row-content/view-row-content.component";
import LeadHome from "../../components/leads/lead-home/lead-home.component";
import SwiperCategoriesList from "../../components/lists/swiper-categories-list/swiper-categories-list.component";
import SwiperNewVideos from "../../components/swipers/swiper-new-videos/swiper-new-videos.component";
import SwiperTopOfWeekVideos from "../../components/swipers/swiper-top-of-week-videos/swiper-top-of-week-videos.component";
import SwiperCategoryVideos from "../../components/swipers/swiper-category-videos/swiper-category-videos.component";
import SwiperPeople from "../../components/swipers/swiper-people/swiper-people.component";
import ViewInlineIconRowHeader from "../../components/views/view-inline-icon-row-header/view-inline-icon-row-header.component";
import ViewIconRowHeader from "../../components/views/view-icon-row-header/view-icon-row-header.component";

import SwiperCities from "../../components/swipers/swiper-cities/swiper-cities.component";

import {
  H2,
  Mobile,
  MobileAndTablet,
  Desktop,
  TabletAndDesktop,
  GlobeIconContainer,
} from "../../components/styles/common";

import GlobeImage from "../../assets/images/global/globe_large.png";

import {
  HeaderElementsContainer,
  HorizontalSeparator,
} from "./home-page.styles";

import {
  MEDIA_QUERY_LIMITS,
  CATEGORIES_CLASSES,
  CATEGORIES_MIN_VIDEOS_NUMBER,
} from "../../config";

const ErrorPage = lazy(
  () => import("../../routes/error-page/error-page.component"),
);

// from top categories are excluded categories with no videos
const CATEGORIES_WITH_VIDEOS = gql`
  query CATEGORIES_WITH_VIDEOS($input: Int) {
    topCategories(input: $input) {
      _id
      name
    }
  }
`;

const Videos = () => {
  const isMobile = useMediaQuery({
    maxWidth: MEDIA_QUERY_LIMITS.maxWidthMobile,
  });

  const { loading, error, data } = useQuery(CATEGORIES_WITH_VIDEOS, {
    variables: {
      input: CATEGORIES_MIN_VIDEOS_NUMBER, // 1
    },
  });

  const getCategoriesFirstSlot = useCallback((categoriesData) => {
    if (!categoriesData) return;
    return categoriesData.slice(0, 3);
  }, []);

  const getCategoriesSecondSlot = useCallback((categoriesData) => {
    if (!categoriesData) return;
    return categoriesData.slice(3);
  }, []);

  return error ? (
    <Suspense fallback={<Loading />}>
      <ErrorPage message={error?.message} />
    </Suspense>
  ) : loading ? (
    <Loading minHeight={"var(--root-available-viewport)"} />
  ) : (
    <>
      <ViewRow
        margin="0 0 var(--root-row-margin) 0"
        content={<ViewRowContent content={<LeadHome />} />}
      />
      <ViewRow
        margin="0 0 var(--root-row-margin) 0"
        header={
          <TabletAndDesktop>
            <ViewRowHeader
              textAlign={"center"}
              width={"100%"}
              padding={"var(--root-vertical-space-1) 0"}
              textTransform={"uppercase"}
            >
              <H2
                style={{
                  "--font-weight": "var(--root-font-weight-bold)",
                  "--font-size": "var(--h3-plus-size)",
                  "--line-height": 1,
                }}
              >
                People
              </H2>
            </ViewRowHeader>
          </TabletAndDesktop>
        }
        content={<ViewRowContent content={<SwiperPeople />} />}
      />
      <ViewRow
        background={"white"}
        margin={isMobile ? "0" : "0 0 var(--root-vertical-space-1) 0"}
        header={
          <ViewRowHeader
            padding={
              "var(--root-vertical-space-1) 0 var(--root-vertical-space-0) var(--root-horizontal-space-1)"
            }
            textTransform={"uppercase"}
          >
            <H2
              style={{
                "--font-weight": "var(--root-font-weight-bold)",
                "--font-size": "var(--h3-plus-size)",
              }}
            >
              Top 10 Settimanale
            </H2>
          </ViewRowHeader>
        }
        content={<ViewRowContent content={<SwiperTopOfWeekVideos />} />}
      />
      <ViewRow
        margin={isMobile ? "0" : "0 0 var(--root-vertical-space-1) 0"}
        content={
          <ViewRowContent
            content={<SwiperCategoriesList data={data?.topCategories} />}
          />
        }
      />
      <ViewRow
        margin={isMobile ? "0" : "0 0 var(--root-vertical-space-1) 0"}
        header={
          <ViewRowHeader
            padding={
              "var(--root-vertical-space-1) 0 0 var(--root-horizontal-space-1)"
            }
            textTransform={"uppercase"}
          >
            <H2
              style={{
                "--font-weight": "var(--root-font-weight-bold)",
                "--font-size": "var(--h3-plus-size)",
              }}
            >
              Nuovi arrivi
            </H2>
          </ViewRowHeader>
        }
        content={<ViewRowContent content={<SwiperNewVideos />} />}
      />
      {getCategoriesFirstSlot(data?.topCategories) &&
        getCategoriesFirstSlot(data?.topCategories).map((category) => (
          <ViewRow
            key={category._id}
            position={"relative"}
            header={
              <>
                <MobileAndTablet>
                  <ViewIconRowHeader
                    category={category.name}
                    padding={
                      "var(--root-vertical-space-2) 0 0 var(--root-horizontal-space-2)"
                    }
                  >
                    <H2
                      style={{
                        "--font-size": "var(--root-font-size-header-mobile)",
                        "--font-weight": "var(--root-font-weight-bold)",
                        "--text-transform": "capitalize",
                      }}
                    >
                      {getCategoryUiName(category.name)}
                    </H2>
                  </ViewIconRowHeader>
                </MobileAndTablet>
                <Desktop>
                  <ViewInlineIconRowHeader category={category.name}>
                    <H2
                      style={{
                        "--font-size": "var(--h3-plus-size)",
                        "--font-weight": "var(--root-font-weight-bold)",
                        "--text-transform": "capitalize",
                      }}
                    >
                      {CATEGORIES_CLASSES[category.name]}
                    </H2>
                  </ViewInlineIconRowHeader>
                </Desktop>
              </>
            }
            content={
              <ViewRowContent
                content={
                  <SwiperCategoryVideos
                    category={category.name}
                    withIcon={true}
                  />
                }
              />
            }
          />
        ))}
      <TabletAndDesktop>
        <ViewRow
          header={
            <ViewRowHeader
              padding={
                "var(--root-vertical-space-1) 0 0 var(--root-horizontal-space-1)"
              }
              textTransform={"uppercase"}
              color={"var(--salmonColor)"}
            >
              <HeaderElementsContainer>
                <H2
                  style={{
                    "--font-size": "var(--h3-size)",
                    "--font-weight": "var(--root-font-weight-bold)",
                  }}
                >
                  Utrend Global
                </H2>
                <GlobeIconContainer
                  style={{
                    "--height": "max(1.71875vw, 33px)",
                  }}
                >
                  <img src={GlobeImage} alt="globe" />
                </GlobeIconContainer>
                <HorizontalSeparator />
              </HeaderElementsContainer>
            </ViewRowHeader>
          }
          content={<ViewRowContent content={<SwiperCities />} />}
        />
      </TabletAndDesktop>

      {getCategoriesSecondSlot(data?.topCategories) &&
        getCategoriesSecondSlot(data?.topCategories).map((category, i) => (
          <ViewRow
            padding={`${
              i === getCategoriesSecondSlot(data?.topCategories).length - 1
                ? "0 0 var(--root-vertical-space-1) 0"
                : "0"
            }`}
            key={category._id}
            position={"relative"}
            header={
              <>
                <MobileAndTablet>
                  <ViewIconRowHeader
                    category={category.name}
                    padding={
                      "var(--root-vertical-space-2) 0 0 var(--root-horizontal-space-2)"
                    }
                  >
                    <H2
                      style={{
                        "--font-size": "var(--root-font-size-header-mobile)",
                        "--font-weight": "var(--root-font-weight-bold)",
                        "--text-transform": "capitalize",
                      }}
                    >
                      {getCategoryUiName(category.name)}
                    </H2>
                  </ViewIconRowHeader>
                </MobileAndTablet>
                <Desktop>
                  <ViewInlineIconRowHeader category={category.name}>
                    <H2
                      style={{
                        "--font-size": "var(--h3-plus-size)",
                        "--font-weight": "var(--root-font-weight-bold)",
                        "--text-transform": "capitalize",
                      }}
                    >
                      {getCategoryUiName(category.name)}
                    </H2>
                  </ViewInlineIconRowHeader>
                </Desktop>
              </>
            }
            content={
              <ViewRowContent
                content={
                  <SwiperCategoryVideos
                    category={category.name}
                    withIcon={true}
                  />
                }
              />
            }
          />
        ))}
      <Mobile>
        <ViewRow
          padding="0 0 1rem 0"
          header={
            <ViewRowHeader
              padding="2vw 0 0 var(--root-horizontal-space-2)"
              textTransform="uppercase"
              color="var(--salmonColor)"
            >
              <HeaderElementsContainer>
                <H2
                  style={{
                    "--font-size": "var(--root-font-size-header-mobile)",
                    "--font-weight": "var(--root-font-weight-bold)",
                  }}
                >
                  Utrend Global
                </H2>
                <GlobeIconContainer
                  style={{
                    "--height": "33px",
                  }}
                >
                  <img src={GlobeImage} alt="globe" />
                </GlobeIconContainer>
                <HorizontalSeparator />
              </HeaderElementsContainer>
            </ViewRowHeader>
          }
          content={<ViewRowContent content={<SwiperCities />} />}
        />
      </Mobile>
    </>
  );
};

export default Videos;
